import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const useStyles = makeStyles((theme) => ({
  wrapper: ({ align }) => ({
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: {
        left: 'flex-start',
        right: 'flex-end',
        center: 'center',
      }[align],
    },
  }),
  button: ({ align }) => ({
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: {
        right: theme.spacing(1.5),
        left: 0,
        center: theme.spacing(1),
      }[align],
      marginRight: {
        right: 0,
        left: theme.spacing(1.5),
        center: theme.spacing(1),
      }[align],
    },
    '& svg': {
      display: 'block',
    },
  }),
}));

const ShareButtons = ({ title, align }) => {
  const classes = useStyles({ align });
  const { href } = useLocation();

  return (
    <Box className={classes.wrapper}>
      <Tooltip title="Share by email">
        <span>
          <EmailShareButton
            subject={title}
            url={href}
            className={classes.button}
          >
            <EmailIcon size={36} />
          </EmailShareButton>
        </span>
      </Tooltip>
      <Tooltip title="Share on Facebook">
        <span>
          <FacebookShareButton
            quote={title}
            url={href}
            className={classes.button}
          >
            <FacebookIcon size={36} />
          </FacebookShareButton>
        </span>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <span>
          <TwitterShareButton
            title={title}
            url={href}
            className={classes.button}
          >
            <TwitterIcon size={36} />
          </TwitterShareButton>
        </span>
      </Tooltip>
    </Box>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

ShareButtons.defaultProps = {
  align: 'left',
};

export default ShareButtons;
